<template>
  <div class="pay-order">
    <div class="bg"></div>
    <div class="payment-container">
      <div class="head">
        <span class="price">待支付：￥{{ orderInfo.amountFinal }}</span>
        <span class="pay-time">请在{{countdownTime[0]}}分{{countdownTime[1]}}秒内完成支付</span>
      </div>
      <ul class="payment-info-box">
        <li><span>停车时长</span><span class="colorFF8200">{{ orderInfo.parkingTime }}</span></li>
        <li><span>车牌</span><span class="colorFF8200">{{ orderInfo.carLicense }}</span></li>
<!--        <li><span>入场时间</span><span class="color999">{{ orderInfo.entranceTime }}</span></li>-->
<!--        <li><span>停车场名</span><span class="color999">{{ orderInfo.parkingLotName }}</span></li>-->
        <li><span>总计</span><span class="color999">￥{{ orderInfo.amountTotal }}</span></li>
        <li><span>优惠折扣</span><span class="color999">-￥{{ orderInfo.amountCoupon }}</span></li>
        <li class="noboder"><span>场内支付</span><span class="color999">-￥{{ orderInfo.payedAmount }}</span></li>
        <div class="pay-box">
          <van-button class="pay-btn" type="info" @click="canPay && payBtn()">立即支付</van-button>
        </div>
      </ul>
      <ul class="paytype" v-if="paymentList.length>1">
        <li v-for="item in paymentList" :key="item.preCode" @click="selectPayment(item.preCode)">
          <span><img class="paytype-icon" :src="'http://image.scsonic.cn/'+item.payIcon" alt="">{{item.payName}}</span>
          <img v-if="scene.prefix == item.preCode" src="@/assets/image/select.png" alt="" class="select-icon">
          <img v-else src="@/assets/image/nochoice.png" alt="" class="select-icon">
        </li>
      </ul>
      <ChargeRule :parkingLotId="orderInfo.parkingLotId" />
    </div>


    <!-- 招行支付，表单提交 -->
    <form id="cmbSubmit" :action="cmbUrl" method="post">
      <input type="hidden" name="jsonRequestData" v-model="cmbPayStr" />
      <input type="hidden" name="charset" value="utf-8" />
    </form>

  </div>
</template>

<script>
import ChargeRule from "@/components/ChargeRule"
import { apiOrderDetail, apiGetPayList, apiUnifiedOrder, apiOpenid, getCarTypeByParkNo } from "@/utils/api"
import { formatCountDownTime, scanApp, JudgeUA, setTimes } from "@/utils/common"
import { Toast, Notify, Dialog } from 'vant'

export default {
  name: 'PayOrder',
  mixins: [],
  components: {
    ChargeRule
  },
  props: {},
  data () {
    return {
      scanAuthInfo: null,
      openid: '',
      userId: '',
      code: '',
      parkinglotId: '',
      toPayResultParam: {
        tradeId: '',
        dataId: '',
        parkingLotId: '',
        sceneType: ''
      },
      orderNo: '',
      orderInfo: {
        agentId: '',
        brandId: '',
        estateId: '',
        recordId: '',
        parkingLotId: '',
        parkingNo: '',
        parkingLotName: '',
        carLicense: '',
        parkingTime: '',
        entranceTime: '',
        amountTotal: '',
        amountCoupon: '',
        amountFinal: '',
        payedAmount: '',
        payable: '',
        payableTip: '',
        exitId: ''
      },
      payParam: null,
      payRequstParam: null,
      scene: {
        prefix: '',
        suffix: '',
        subclass: ''
      },
      paymentList: [],
      timer: null,
      countdownTime: ["--", "--"],
      canPay: false,
      tradeId: '',
      msg: '',
      cmbPayStr: '', // 招行支付参数
      // 生产环境   https://netpay.cmbchina.com/netpayment/BaseHttp.dll?MB_APPQRPay
      // 测试环境   http://121.15.180.66:801/netpayment/BaseHttp.dll?MB_APPQRPay
      cmbUrl: 'http://121.15.180.66:801/netpayment/BaseHttp.dll?MB_APPQRPay'
    }
  },
  computed: {},
  watch: {},
  created () {
    this.scene.subclass = scanApp()
    let urlParam = this.$route.query
    if(urlParam.p){
      this.scene.suffix = urlParam.p.split('-')[0]
      this.orderNo = urlParam.p.split('-')[1]
      this.userId = urlParam.userId ? urlParam.userId : ''
      if(this.scene.subclass == 'A'){
        this.scanAuthInfo = JSON.parse(localStorage.getItem('scanAuthInfo'))
        this.openid = urlParam.openid
      }
    }else{
      Notify({ type: 'warning', message: '参数错误：缺少参数' })
      return
    }
    this.getCarType(this.orderNo)
  },
  mounted () {},
  destroyed () {},
  methods: {
    getOrderDetail (){
      let that = this
      let param = {
        parkingNo: this.orderNo
      }
      apiOrderDetail(param)
      .then(result => {
        if (result.status == "200") {
          if (result.data.payable == 0) {
            Notify({type: "danger",message: result.data.payableTip})
            return
          }

          that.orderInfo = result.data
          that.getPaymentList()
        } else {

        }
      })
      .catch(err => {
        window.console.log(err)
      })
    },
    getPaymentList (){
      let that = this
      let param = {
        parkingLotId: this.orderInfo.parkingLotId,
        client: this.scene.subclass
      }
      apiGetPayList(param)
      .then(result => {
        if (result.status == "200") {
          if(result.data.length == 0){
            Notify({type: "danger",message: '未配置支付渠道,无法完成支付,请联系管理员', duration: 4000})
          }else{
            that.paymentList = result.data
            that.scene.prefix = result.data[0].preCode
            that.setTimer()
            that.canPay = true
          }
        } else {
          Notify({type: "danger",message: result.message})
        }
      })
      .catch(err => {
        window.console.log(err)
      })
    },
    setTimer(t) {
      let time = t ? t : 300 // 默认5分钟
      this.timer = setInterval(() => {
        this.countdownTime = formatCountDownTime(time).split(":")
        time--
        if (time < 0) {
          clearInterval(this.timer)
          this.timer = null
          // location.reload()
          Toast.loading({
            message: '订单过期，自动刷新...',
            forbidClick: true,
            overlay: true,
            loadingType: 'spinner',
            duration: 1000,
            onClose: function(){
              location.reload()
            }
          })
        }
      }, 1000);
    },
    // 切换支付方式
    selectPayment (preCode){
      this.scene.prefix = preCode
    },
    payBtn (){
      let that = this
      if(this.orderInfo.amountFinal == 0){
        Notify({ type: "danger", message: '您无需支付费用' })
        return
      }

      this.payParam = {
        openId: this.openid,
        unionId: this.scanAuthInfo != null ? this.scanAuthInfo.unionid : '',
        userId: this.userId,
        sceneType: this.scene.prefix+this.scene.subclass+this.scene.suffix,
        carLicense: this.orderInfo.carLicense,
        dataId: this.orderInfo.recordId,
        parkingLotId: this.orderInfo.parkingLotId,
        brandId: this.orderInfo.brandId,
        estateId: this.orderInfo.estateId,
        agentId: this.orderInfo.agentId,
        incomeName: this.orderInfo.carLicense + "停车缴费",
        amountTotal: this.orderInfo.amountTotal,
        amountDiscount: this.orderInfo.amountCoupon,
        drivewayId: this.orderInfo.exitId,
        amountCurrentPay: this.orderInfo.amountFinal,
        entranceTime: this.orderInfo.entranceTime,
        // amountCurrentPay: 0.01
      }
      let exitTimes = new Date(this.orderInfo.exitTime.replace(/\-/g, "/")).getTime()
      this.payParam.maxPayTime = setTimes(exitTimes + 300000)

      Toast.loading("拉起支付中...")
      apiUnifiedOrder(this.payParam)
      .then(result => {
        if (result.status == "200") {
          if (result.data.success) {
            that.toPayResultParam = {
              tradeId: result.data.tradeId,
              dataId: that.orderInfo.recordId,
              parkingLotId: that.orderInfo.parkingLotId,
              sceneType: result.data.sceneType
            }

            let temporaryCar = localStorage.getItem("temporaryCar")
            if(temporaryCar == that.orderInfo.carLicense){
              localStorage.removeItem("temporaryCar");
            }

            that.toPay(result.data)
          } else {
            Toast.clear()
            Notify({ type: "danger", message: result.data.message })
          }
        } else if (result.status == "111") {
          Toast.clear()
          Dialog.alert({
            title: '提示信息',
            message: result.message,
          }).then(() => {
            // on close
          })
        } else {
          Toast.clear()
          Notify({ type: "danger", message: result.message })
        }
      })
      .catch(err => {
        window.console.log(err);
      })
    },
    // 选择支付方式
    toPay(payData){
      Toast.clear()
      const ua = JudgeUA()
      switch (ua) {
        case 'wechat':
          this.wxPay(payData)
          break;
        case 'alipay':
          this.aliPay(payData)
          window.location.href = payData.payUrl
          break;
        case 'jdapp':
        case 'jdjr':
          this.jdPay(payData)
          break;
        case 'cmb':
          this.cmbPay(payData)
          break;
        default:
          Notify({ type: "danger", message: '不支持该种支付方式' })
          break;
      }
    },
    // 跳转支付结果页面
    toPayResult(){
      this.$router.push({
        path: "/payresult",
        query: this.toPayResultParam
      })
    },
    // 微信支付
    wxPay (payParam){
      if(payParam.payUrl){
        window.location.href = payParam.payUrl
      }else{
        let obj = payParam.jsApiObject
        if (typeof WeixinJSBridge == "undefined") {
          if (document.addEventListener) {
            document.addEventListener(
              "WeixinJSBridgeReady",
              this.wxBridgeReady,
              false
            );
          } else if (document.attachEvent) {
            document.attachEvent("WeixinJSBridgeReady", this.wxBridgeReady);
            document.attachEvent("onWeixinJSBridgeReady", this.wxBridgeReady);
          }
        } else {
          this.wxBridgeReady(obj);
        }
      }
    },
    wxBridgeReady(obj) {
      Toast.clear()
      let that = this;
      WeixinJSBridge.invoke("getBrandWCPayRequest",
        {
          appId: obj.appId, //公众号名称，由商户传入
          timeStamp: obj.timeStamp, //时间戳，自1970年以来的秒数
          nonceStr: obj.nonceStr, //随机串
          package: obj.package,
          signType: obj.signType, //微信签名方式：
          paySign: obj.paySign //微信签名
        },
        function(res) {
          if (res.err_msg == "get_brand_wcpay_request:ok") {
            //使用以上方式判断前端返回,微信团队郑重提示：
            //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
            that.toPayResult()
          } else if (res.err_msg == "get_brand_wcpay_request:cancel") {
            Toast("您已取消支付")
          } else {
            Notify({ type: "danger", message: res.err_msg });
          }
        }
      )
    },
    // 支付宝支付
    aliPay (payParam){
      if(payParam.payUrl){
        window.location.href = payParam.payUrl
      }else{
        Notify({ type: "danger", message: '支付宝支付参数错误！' })
      }
    },
    // 京东支付
    jdPay (payParam){
      if(payParam.payUrl){
        window.location.href = payParam.payUrl
      }else{
        Notify({ type: "danger", message: '京东支付参数错误！' })
      }
    },
    // 招行支付
    cmbPay (payParam){
      if(payParam.payUrl){
        this.cmbPayStr = payParam.payUrl
        document.getElementById("cmbSubmit").submit()
      }else{
        Notify({ type: "danger", message: '招行支付参数错误！' })
      }
    },
    // 建行支付
    ccbPay (payParam){

    },
    // 银联云闪付
    cuqpPay (payParam){

    },
    getCarType(p){
      let that = this
      const requestP = {
        parkingNo: p
      }
      getCarTypeByParkNo(requestP)
        .then(result => {
          console.log(result)
          if (result.status == "200") {
            if (result.data.carType == 2 ||result.data.carType == '2') {
              let param = {
                drivewayId: result.data.drivewayId,
                license: result.data.license,
                parkinglotId: result.data.parkinglotId,
                drivewayCode: that.drivewayCode,
                openId: that.openid,
                payType:"3003"
              };
              that.$router.push({
                path: "/arrears",
                query: param
              })
            }else{
              that.getOrderDetail()
            }
          }
        })
        .catch(err => {
          Notify({ type: "danger", message: err })
        });
    },
  },
}
</script>

<style scoped lang="scss">
.pay-order{
  .bg{
    width: 100%;
    height: 320px;
    background: url('../../assets/image/bjinformation.png');
    background-size: cover;
    position: absolute;
    z-index: -1;
  }
  .payment-container{
    padding: 30px;
    .head{
      text-align-last: left;
      margin-top: 30px;
      margin-bottom: 30px;
      .price{
        font-size: 40px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        display: block;
      }
      .pay-time{
        font-size: 24px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
      }
    }
    .payment-info-box{
      width: 690px;
      //height: 633px;
      background: #FFFFFF;
      box-shadow: 0px 4px 20px 0px rgba(174, 185, 190, 0.7);
      border-radius: 10px;
      font-size: 24px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #333333;
      padding: 0 25px;
      box-sizing: border-box;
      margin-bottom: 29px;
      li{
        display: flex;
        justify-content: space-between;
        height: 86px;
        line-height: 86px;
        border-bottom: 2px solid #E5E5E5;
        padding: 0 22px 0 13px;
        .color999{
          color: #999;
        }
        .colorFF8200{
          color: #FF8200;
        }
      }
      li.noboder{
        border-bottom: 0;
      }
    }
    .paytype{
      font-size: 24px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #333333;
      width: 690px;
      background: #FFFFFF;
      box-shadow: 0px 4px 20px 0px rgba(174, 185, 190, 0.7);
      border-radius: 10px;
      padding: 0 25px;
      box-sizing: border-box;
      li{
        display: flex;
        justify-content: space-between;        
        align-items: center;
        height: 96px;
        line-height: 96px;
        border-bottom: 2px solid #E5E5E5;
        .paytype-icon{
          width: 38px;
          height: 38px;
          vertical-align: middle;
          margin-right: 19px;
        }
        .select-icon{
          width: 38px;
          height: 38px;
        }
      }
      li:last-child{
        border-bottom: 0;
      }
    }
  }
  .pay-box{
    //position: fixed;
    width: 100%;
    //height: 220px;
    padding: 5px 0;
    bottom: 0;
    text-align: center;
    background-color: #fff;
    //box-shadow: 0px -2px 5px 0px rgba(174, 185, 190, .5);
    .price{
      font-size: 36px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #FF7F00;
      line-height: 60px;
    }
    .pay-btn{
      width: 600px;
      height: 80px;
      background: linear-gradient(-67deg, #26A0A5, #8DE9C6);
      border-radius: 40px;
      border: none;
      font-size: 28px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
      margin-bottom: 50px;
    }
  }

}
</style>