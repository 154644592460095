<template>
  <div class="unlicensed-car-out">
    <h1>无牌车出场</h1>
  </div>
</template>

<script>

export default {
  name: 'UnlicensedCarOut',
  mixins: [],
  components: {},
  props: {},
  data () {
    return {
      aaaa: '12312321'
    }
  },
  computed: {},
  watch: {},
  created () {},
  mounted () {},
  destroyed () {},
  methods: {},
}
</script>

<style scoped lang="scss">

</style>