<template>
  <div class="receive-coupon">
    <img class="header-bg" src="@/assets/image/bjinformation2.png" alt="">
    <p class="title">停车详情</p>
    <div class="parking-info">
      <div class="info-item">
        <span>停车场名</span><span class="cFF8200">{{carinfo.parkingName}}</span>
      </div>
      <div class="info-item">
        <span>车牌号</span><span class="cFF8200">{{carinfo.carlicense}}</span>
      </div>
      <div class="info-item">
        <span>入场时间</span><span class="c999999">{{carinfo.entranceTime}}</span>
      </div>
      <div class="info-item">
        <span>停车时长</span><span class="c999999">{{carinfo.totalTime}}</span>
      </div>
      <div class="info-item">
        <span>停车费用</span><span class="c999999">{{carinfo.amountFinal}} 元</span>
      </div>
    </div>
    <div class="parking-img">
      <span>入场图片</span>
      <img class="car-img" :src="carinfo.img">
    </div>
    <van-button class="linear-gradient receive-btn"
     type="info" 
    :loading="loading"
    loading-text="领取中..."
    :disabled="disabled"
    @click="getCoupon">
      领取优惠券
    </van-button>
  </div>
</template>

<script>
import { apiGetCarinfo, apiGetQrcodeCoupon } from "@/utils/api"
import defaultImg from '@/assets/image/merchant-car-imgbg2.png';
import { Toast, Notify } from 'vant'

export default {
  name: 'ReceiveCoupon',
  mixins: [],
  components: {},
  props: {},
  data () {
    return {
      merchantInfo: null,
      loading: false,
      disabled: true,
      duration: '',
      carinfo: {
        parkingName: '停车场名称',
        carlicense: '车牌号',
        entranceTime: '入场时间',
        totalTime: '停车时长',
        amountFinal: '停车费用',
        img: ''
      },
      carLicense: '',
      qrcodeId: '',
    }
  },
  computed: {},
  watch: {},
  created () {
    this.merchantInfo = JSON.parse(localStorage.getItem('merchantInfo'))
    const urlParam = this.$route.query
    this.carLicense = urlParam.carLicense
    this.qrcodeId = urlParam.qrCodeId


    this.getCarInfo()
  },
  mounted () {},
  destroyed () {},
  methods: {
    getCarInfo () {
      let that = this

      let param = {
        qrCodeId: this.qrcodeId,
        carLicense: this.carLicense
      }
      apiGetCarinfo(param)
      .then(result => {
        if (result.status == "200") {
            that.disabled = false
            that.duration = parseInt(result.data.datsum)
            that.carinfo.parkingName = result.data.parkingLotName
            that.carinfo.carlicense = result.data.carLicense
            that.carinfo.entranceTime = result.data.entranceTime
            that.carinfo.totalTime = result.data.parkingTime
            that.carinfo.amountFinal = result.data.amountFinal
            that.carinfo.img = result.data.entrance_photo? "https://image.scsonic.cn/"+result.data.entrance_photo : defaultImg
        }else {
          that.disabled = true
          Notify({ type: "danger", message: result.message })
        }
      })
      .catch(err => {
        window.console.log(err)
      })
    },
    getCoupon () {
      let that = this

      this.loading = true
      const requestP = {
        qrCodeId: this.qrcodeId,
        carLicense: this.carLicense,
        presentTime: Math.ceil(this.duration/3600),
        presentMoney: this.carinfo.amountFinal
      }
      apiGetQrcodeCoupon(requestP)
        .then(result => {
          that.loading = false
          if (result.status == "200") {
            that.$router.push("/coupon-success")
          } else {
            Notify({type: "danger", message: result.message})
          }
        })
        .catch(err => {
          that.loading = false
          window.console.log(err)
        });
    }
  },
}
</script>

<style scoped lang="scss">
.receive-coupon{
  font-size: 24px;
  color: #333333;
  .header-bg{
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 280px;
    top: 0;
    left: 0;
  }
  .title{
    font-size: 40px;
    font-weight: 500;
    color: #FFFFFF;
    margin: 70px 0 30px 30px;
    text-align: left;
  }
  .parking-info{
    width: 690px;
    height: 461px;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px 0px rgba(174, 185, 190, 0.7);
    border-radius: 10px;
    margin: 26px auto;
    padding: 8px 25px;
    box-sizing: border-box;
    .info-item{
      height: 88px;
      line-height: 88px;
      border-bottom: 1px solid #E5E5E5;
      display: flex;
      justify-content: space-between;
      padding: 0 20px 0 13px;
      box-sizing: border-box;
    }
    .c999999{color: #999999;}
    .cFF8200{color: #FF8200;}
  }
  .parking-img{
    width: 690px;
    height: 393px;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px 0px rgba(174, 185, 190, 0.7);
    border-radius: 10px;
    margin: 0 auto;
    margin-bottom: 80px;
    padding: 40px;
    box-sizing: border-box;
    text-align: left;
    .car-img{
      width: 606px;
      height: 262px;
      // border: 1px solid #C9C9C9;
      margin-top: 22px;
    }
  }
  .receive-btn{
    width: 600px;
    height: 80px;
    border-radius: 4px;
  }
}
</style>