<template>
  <div class="outside-fixedcode">
    <div class="no-car" v-show="noOrder">
      <img src="@/assets/image/chahua.png" alt="">
      <span>{{noOrderText}}</span>
    </div>
  </div>
</template>

<script>
import { apiExitOrder, apiUnlicensedCarOrder } from "@/utils/api"
import { JudgeUA } from "@/utils/common"
import { Toast, Notify } from 'vant'

export default {
  name: 'OutsideFixedcode',
  mixins: [],
  components: {},
  props: {},
  data () {
    return {
      orderNo: '',
      noOrderText: '未查询到出口订单',
      exitCode: '',
      sceneSuffix: '',
      scanAuthInfo: null,
      noOrder: false,
      ua: null
    }
  },
  computed: {},
  watch: {},
  created () {
    let urlParam = this.$route.query

    this.ua = JudgeUA()
    if(urlParam.p){
      this.sceneSuffix = urlParam.p.split('-')[0]
      this.exitCode = urlParam.p.split('-')[1]
      this.scanAuthInfo = JSON.parse(localStorage.getItem('scanAuthInfo'))
    }else{
      Notify({ type: 'warning', message: '参数错误：缺少参数' })
      return
    }

    if(this.sceneSuffix == '2001'){
      this.getOrderNo()
    }else if(this.sceneSuffix == '2002'){
      this.toPayorder()
    }
  },
  mounted () {},
  destroyed () {},
  methods: {
    getOrderNo(){
      let that = this
      const requestP = {
        drivewayCode: this.exitCode
      };
      apiExitOrder(requestP)
        .then(result => {
          if (result.status == "200" && result.data != null) {
            let queryParam = {
              p: this.sceneSuffix + '-' + result.data.parkingNo
            }
            that.$router.push({
              path: '/payorder?isDesc=true',
              query: queryParam
            })
          } else {
            that.noOrder = true
            if(that.ua == 'wechat'){
              that.noOrderText = '未查询到出口订单，将为您查询无牌车订单'
              Toast.loading({
                message: '查询中...',
                forbidClick: true,
                duration: 3000,
                onClose: function(){
                  that.getNolicenseOrderNo()
                }
              })
            }else{
              that.noOrderText = '未查询到出口订单'
            }
          }
        })
        .catch(err => {
          Notify({ type: "danger", message: err })
        });
    },
    toPayorder(){
      let queryParam = {
        p: this.sceneSuffix + '-' + this.exitCode
      }
      this.$router.push({
        path: '/payorder',
        query: queryParam
      })
    },
    getNolicenseOrderNo(){
      let that = this
      const requestP = {
        drivewayCode: this.exitCode,
        unionId: this.scanAuthInfo.unionid
      };
      apiUnlicensedCarOrder(requestP)
        .then(result => {
          if (result.status == "200" && result.data != null) {
            let queryParam = {
              p: this.sceneSuffix + '-' + result.data.parkingNo
            }
            that.$router.push({
              path: '/payorder',
              query: queryParam
            })
          } else {
            that.noOrderText = '未查询到无牌车订单'
          }
        })
        .catch(err => {
          Notify({ type: "danger", message: err })
        });
    }

  }
}
</script>

<style scoped lang="scss">
  .no-car{
    img{
      width: 394px;
      height: 338px;
      display: block;
      margin: {
        top: 140px;
        left: auto;
        right: auto;
        bottom: 60px;
      }
    }
    span{
      font-size: 30px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #999999;
    }
  }
</style>