<!-- 领取成功页面 -->
<template>
  <div class="container">
    <div class="page_top">
      <div class="pay_icon">
        <img src="@/assets/image/paysuccess.png" alt />
      </div>
      <div class="pay_free_time">优惠券领取成功！</div>
    </div>
    <van-button class="complete" @click="complete" type="primary" color="#16B19A">完成</van-button>
  </div>
</template>

<script>
import { closeWebviewEvent } from "@/utils/common";

export default {
  components: {},
  data() {
    return {};
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  created() {
    window.addEventListener("popstate", closeWebviewEvent, false);
  },
  //方法集合
  methods: {
    complete() {
      closeWebviewEvent();
    }
  }, 
};
</script>
<style scoped lang="scss">
//@import url(); 引入公共css类
.container {
  height: 100vh;
  text-align: center;
  background: #fff;
  padding-top: 200px;
  .page_top {
    padding: 64px 0;
    background: #fff;
    .pay_icon {
      img {
        width: 92px;
        height: 92px;
      }
    }
    .pay_free_time {
      margin-top: 60px;
      color: #444444;
      height: 120px;
      line-height: 120px;
      font-size: 40px;
    }
  }
  .complete {
    position: fixed;
    bottom: 20px;
    left: 32px;
    width: 686px;
    border-radius: 8px;
  }
}
</style>