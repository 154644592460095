<template>
  <div class="unlicensed-car-in">
    <div class="head">
      <img class="wx-heae-img" :src="userHeadImg" alt="头像">
      <span class="wx-name">{{ name }}</span>
    </div>
    <div v-show="!hasUnclosedOrder"  class="car-box">
      <span>临时车牌</span>
      <span class="temp-car">{{ tempCar }}</span>
    </div>
    <van-button
      v-show="!hasUnclosedOrder" 
      class="btn-in"
      type="primary"
      :loading="loading"
      :loading-text="loadingText"
      @click="isClick && onNoCarlicenseInClick()"
    >无牌车入场</van-button>
    <span v-show="!hasUnclosedOrder"  class="des">点击按钮入场</span>

    <div v-show="hasUnclosedOrder" class="unclosed-order">
      <p class="des">未结束订单信息</p>
      <div class="order-info">
        <div>
          <span class="title">车场名称</span>
          <span style="color: #FF8200;">{{orderInfo.parkingLotName}}</span>
        </div>
        <div>
          <span class="title">车牌号</span>
          <span style="color: #FF8200;">{{orderInfo.carLicense}}</span>
        </div>
        <div style="border:none;">
          <span class="title">入场时间</span>
          <span style="color: #999999;">{{orderInfo.entranceTime || "无入场记录" }}</span>
        </div>
      </div>
      <p class="footer-des">请联系现场人员或按设备上的帮助按钮</p>
    </div>

    <van-dialog class="dialog-res" v-model="show" confirm-button-text="知道了" confirm-button-color="#FE6402" @closed="closeDialog">
      <span class="dialog-cont">开闸成功，欢迎光临！</span>
    </van-dialog>
  </div>
</template>

<script>
import { getUserInfoByOpenid, apiUnlicensedCarScanIn, getCarType } from "@/utils/api";
import { JudgeUA, closeWebviewEvent, randomCarlicense } from "@/utils/common";
import { Notify } from "vant";

export default {
  name: 'UnlicensedCarIn',
  mixins: [],
  components: {},
  props: {},
  data () {
    return {
      show: false,
      isClick: false,
      loading: false,
      loadingText: "",
      userHeadImg: '',
      name: '用户名',
      tempCar: '临时车牌号',
      drivewayCode: '',
      scanAuthInfo: null,
      hasUnclosedOrder: false,
      orderInfo: {
        parkingLotName: '',
        carLicense: '',
        entranceTime: ''
      }
    }
  },
  computed: {},
  watch: {},
  created () {
    let that = this;

    const ua = JudgeUA()
    if(ua != 'wechat'){
      Notify({ type: "danger", message: "请使用微信扫码入场！" })
      return
    }

    let obj = that.$route.query;
    const qrcodeType = obj.p.split("-")[0];
    this.drivewayCode = obj.p.split("-")[1];
    this.scanAuthInfo = JSON.parse(localStorage.getItem('scanAuthInfo'))
    this.userHeadImg = this.scanAuthInfo.headimgurl
    this.name = this.scanAuthInfo.nickname
    this.tempCar = randomCarlicense();

    if (qrcodeType != "3001") {
      Notify({ type: "danger", message: "场景编码出错！" });
      return;
    }

    this.getCarType()
    window.addEventListener("popstate", closeWebviewEvent, false)

  },
  mounted () {},
  destroyed () {
    Notify.clear();
  },
  methods: {
    onNoCarlicenseInClick() {
      let that = this;
      that.loading = true;
      that.loadingText = "开闸中...";
      const param = {
        token: "nocarlicense",
        unionId: this.scanAuthInfo.unionid,
        drivewayCode: this.drivewayCode,
        carLicense: this.tempCar
      };
      apiUnlicensedCarScanIn(param)
        .then(resulst => {
          that.loading = false;
          that.loadingText = "";
          if (resulst.status == "200") {
            that.isClick = false
            that.show = true
            localStorage.setItem("temporaryCar", that.tempCar)
          }else if(resulst.status == "400"){
            that.tempCar = randomCarlicense();
            Notify({
              type: "danger",
              message: "临时车牌重复，请重新点击无牌车入场按钮！"
            });
          }else {
            that.isClick = false
            Notify({ type: "danger", message: resulst.message });
          }
        })
        .catch(err => {
          that.loading = false;
          that.loadingText = "";
          window.console.log(err);
        });
    },
    getCarType() {
      let that = this
      if(this.scanAuthInfo != null){
        this.isClick = true

        let param = {
          unionId: this.scanAuthInfo.unionid,
          drivewayCode: this.drivewayCode
        }
        getCarType(param)
        .then(result => {
          if (result.status == "200") {
            if (result.data.carType == 2 ||result.data.carType == '2') {
              let param = {
                drivewayId: result.data.drivewayId,
                license: result.data.license,
                parkinglotId: result.data.parkinglotId,
                drivewayCode: that.drivewayCode,
                payType:"3003"
              };
              that.$router.push({
                path: "/arrears",
                query: param
              })
            }else if (result.data.carType == 3) {
              that.$router.push({
                path: "/stateless"
              })
            }else if (result.data.carType == 4) {
              that.orderInfo.parkingLotName = result.data.parkingRecord.parkinglotName
              that.orderInfo.carLicense = result.data.parkingRecord.carLicense
              that.orderInfo.entranceTime = result.data.parkingRecord.entranceTime

              that.hasUnclosedOrder = true
            }
          } else {
            Notify({ type: "danger", message: result.message });
          }
        })
        .catch(err => {
          window.console.log("err：" + err)
        })
      }else{
        Notify({ type: "danger", message: "获取用户信息失败，请重新扫码" });
      }
    },
    closeDialog() {
      closeWebviewEvent()
    }
  },
} 
</script>

<style scoped lang="scss">
.unlicensed-car-in{
  display: flex;
  flex-direction: column;
  align-content: center;
  span{
    display: block;
  }
  .head{
    padding-top: 160px;
    .wx-heae-img{
      width: 128px;
      height: 128px;
      display: inline-block;
      border-radius: 50%;
    }
    .wx-name{
      font-size: 28px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #666666;
    }
  }
  .car-box{
    font-size: 30px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #666666;
    line-height: 60px;
    margin-top: 110px;
    .temp-car{
      font-size: 50px;
      font-weight: bold;
      color: #333333;
    }
  }
  .btn-in{
    width: 600px;
    height: 80px;
    background: linear-gradient(-67deg, #26A0A5, #8DE9C6);
    border-radius: 40px;
    font-size: 28px;
    font-weight: 500;
    color: #FFFFFF;
    margin: 70px auto 0;
    border: none;
  }
  .des{
    font-size: 24px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #3AA9AE;
    margin-top: 36px;
  }
  .dialog-res{
    border-radius: 8px;
    ::v-deep .van-dialog__content{
      padding: 95px 0 57px;
      .dialog-cont{
        font-size: 40px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #222222;
      }
    }
  }
  .unclosed-order{
    margin-top: 50px;
    .des{
      font-size: 30px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #E0424D;
      line-height: 60px;
    }
    .order-info{
      width: 690px;
      height: 298px;
      background: #FFFFFF;
      box-shadow: 0px 4px 20px 0px rgba(174, 185, 190, 0.7);
      border-radius: 10px;
      padding: 0 24px;
      box-sizing: border-box;
      margin: 0 auto;
      font-size: 24px;
      color: #333333;
      > div{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 98px;
        border-bottom: 1px solid #E5E5E5;
      }
    }
    .footer-des{
      font-size: 24px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #3AA9AE;
      margin-top: 46px;
    }
  }
}
</style>