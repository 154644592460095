<template>
  <div class="pay-result">
    <img src="@/assets/image/success.png" alt="">
    <p v-if="insidePay">{{paidFreeTime}}前可免费离场</p>
    <p v-else>支付成功,请尽快离场！</p>
    <div class="btn-group">
      <!-- <van-button class="back-btn" type="info">索取发票</van-button> -->
      <!-- <van-button class="back-btn" type="info">返回</van-button> -->
      <van-button class="back-btn" @click="complete" type="info">完成</van-button>
    </div>
  </div>
</template>

<script>
import { apiPayResult, apieInvoice } from "@/utils/api"
import { Toast, Notify } from 'vant'
import { closeWebviewEvent } from "@/utils/common";

export default {
  name: 'PayResult',
  mixins: [],
  components: {},
  props: {},
  data () {
    return {
      insidePay: false,
      tradeId: '',
      dataId: '',
      parkingLotId: '',
      sceneType: '',
      paidFreeTime: 0
    }
  },
  computed: {},
  watch: {},
  created () {
    const urlParam = this.$route.query
    this.tradeId = urlParam.tradeId
    this.dataId = urlParam.dataId
    this.parkingLotId = urlParam.parkingLotId
    this.sceneType = urlParam.sceneType

    if(this.sceneType.slice(4) == '1001' || this.sceneType.slice(4) == '1002'){
      this.insidePay = true
    }
    window.addEventListener("popstate", closeWebviewEvent, false)
    this.getPayResult()
  },
  mounted () {},
  destroyed () {},
  methods: {
    getPayResult (){
      let that = this
      let param = {
        tradeId: this.tradeId
      }
      apiPayResult(param)
      .then(result => {
        if (result.status == "200") {
          that.paidFreeTime = that.formatTime(result.data.paidFreeTime)
          console.log(result.data)

        } else {

        }
      })
      .catch(err => {
        window.console.log(err)
      })
    },
    formatTime(val) {
      let h = 0,
        m = 0;
      let hour = new Date().getHours();
      let min = new Date().getMinutes();
      let totalMin = min + val;
      if (totalMin > 60) {
        h = hour + 1 == 24 ? "00" : this.formatNumber(hour + 1);
        m = this.formatNumber(totalMin % 60);
      } else {
        h = this.formatNumber(hour);
        m = this.formatNumber(totalMin);
      }
      return h + ":" + m;
    },
    formatNumber(n) {
      n = n.toString();
      return n[1] ? n : "0" + n;
    },
    complete() {
      closeWebviewEvent();
    },
  },
}
</script>

<style scoped lang="scss">
.pay-result{
  img{
    width: 262px;
    height: 221px;
    margin-top: 200px;
    position: relative;
    left: 20px;
  }
  p{
    font-size: 45px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #535353;
    // line-height: 33px;
    margin: 0;
    margin-bottom: 102px;
    margin-top: 40px;
  }
  .btn-group{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .back-btn{
      width: 375px;
      height: 80px;
      background: linear-gradient(-67deg, #26A0A5, #8DE9C6);
      border-radius: 40px;
      font-size: 30px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
      border: none;
      margin-bottom: 40px;
    }
  }
}
</style>