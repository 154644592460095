<template>
  <div class="inquirepay">
    <img class="head-bg-img" src="@/assets/image/bjpay.png" alt="">
    <p class="title">我的停车</p>
    <div class="carlicense-box">
      <span class="caption">{{title}}</span>
      <div class="carlicense-spans">
        <span @click="showCnKeyBoard" class="">{{carlicenseArr[0] ? carlicenseArr[0] : ''}}</span>
        <span @click="showKeyBoard" class="">{{carlicenseArr[1] ? carlicenseArr[1] : ''}}</span>
        <span class="dot">·</span>
        <span @click="showKeyBoard" class="">{{carlicenseArr[2] ? carlicenseArr[2] : ''}}</span>
        <span @click="showKeyBoard" class="">{{carlicenseArr[3] ? carlicenseArr[3] : ''}}</span>
        <span @click="showKeyBoard" class="">{{carlicenseArr[4] ? carlicenseArr[4] : ''}}</span>
        <span @click="showKeyBoard" class="">{{carlicenseArr[5] ? carlicenseArr[5] : ''}}</span>
        <span @click="showKeyBoard" class="">{{carlicenseArr[6] ? carlicenseArr[6] : ''}}</span>
        <span v-if="newEnergyCar" @click="showKeyBoard" class="">{{carlicenseArr[7] ? carlicenseArr[7] : ''}}</span>
        <span v-else class="new-span" @click="changeCarType"><b>+</b><br/><b class="text">新能源</b></span>
      </div>
    </div>
    <div class="history">
      <span class="title-text">历史搜索：</span>
      <span class="no-history" v-show="historyCars.length == 0">您还没有搜索历史哦~</span>
      <ul>
        <li v-for="(item, index) in historyCars" :key="index" @click="fillCar(item)">
          <img src="@/assets/image/che.png" alt="" class="car-icon">
          <span class="number"><span v-if="index < 9">0</span>{{ index+1 }}.</span>
          <span class="car">{{ item }}</span>
          <img src="@/assets/image/shanchu.png" alt="" class="del-icon" @click.stop="delHistoryCar(item)">
        </li>
      </ul>
    </div>
    <van-button class="linear-gradient search-btn" type="info" @click="toPaymentInfo()">
      查询缴费信息
    </van-button>
    <van-dialog class="del-dialog" v-model="delDialog" show-cancel-button :before-close="beforeClose">
      <p class="carlincese">{{ delCar }}</p>
      <span class='dialog-span'>请确认是否删除该搜索记录</span>
    </van-dialog>
    <KeyBoard
      :showKeyBoard="show"
      :showCNKeyBoard="showCn"
      v-on:input-over="hideKeyBoard"
      v-on:change-press="changeKeyBoard"
      v-on:key-press="getPressKeyValue"
      v-on:del-press="delCarKeyPress"
      v-on:clear-press="clearCarInput"
    />
  </div>
</template>

<script>
import KeyBoard from "@/components/KeyBoard"
import { apiGetTemporaryCar, apiInsideOrder } from "@/utils/api"
import { setHistoryCars, delHistoryCars, JudgeUA, carlicensePrefix } from "@/utils/common"
import { Toast, Notify } from 'vant'

export default {
  name: 'InsideFixedcode',
  mixins: [],
  components: {
    KeyBoard
  },
  props: {},
  data () {
    return {
      title: '请输入车牌号',
      delDialog: false,
      delCar: '',
      show: false,
      showCn: true,
      init: true,
      loading: false,
      showCertDialog: false,
      newEnergyCar: false,
      carlicenseArr: [],
      carLength: 7,
      historyCars: [],
      sceneSuffix: '',
      parkingLotCode: '',
      scanAuthInfo: null
    }
  },
  computed: {},
  watch: {
    carlicenseArr: function() {
      const len = this.carlicenseArr.length;
      len == 0 && ((this.showCn = true), (this.init = true));
      len == 1 && ((this.showCn = false), (this.init = false));
    }
  },
  created () {
    /**
     * 获取扫码参数，url中
     * p=1001-6501020005  支付类别-停车场编码 sceneSuffix-parkingLotCode
     * 第一次授权，url中包含参数openid 和 unionid
     * 
     * 判断url中是否有openid和unionid，进行本地存储
     */
    let urlParam = this.$route.query
    if(urlParam.p){
      this.sceneSuffix = urlParam.p.split('-')[0]
      this.parkingLotCode = urlParam.p.split('-')[1]
      this.scanAuthInfo = JSON.parse(localStorage.getItem('scanAuthInfo'))
    }else{
      Notify({ type: 'warning', message: '参数错误：缺少参数' })
      return
    }

    if (this.carlicenseArr.length == 0) {
      this.showCn = true;
    } else if (this.carlicenseArr.length == 1) {
      this.showCn = false;
    }

    this.getHistoryCar()
    
    const ua = JudgeUA()
    if(ua == 'wechat'){
      let code = this.parkingLotCode.substr(0, 4)
      let prefix = carlicensePrefix(code)
      this.province = prefix.province
      this.city = prefix.city

      let temporaryCar = localStorage.getItem("temporaryCar")
      if(temporaryCar){
          this.title = "系统检测到您有以下临停车"
          this.carlicenseArr = temporaryCar.split('')
      }else{
        if(this.province != ''){
          this.showCn = false
          this.carlicenseArr[0] = this.province
          this.carlicenseArr[1] = this.city
        }
        this.title = "请输入车牌查询"
      }
    }
  },
  mounted () {},
  destroyed () {},
  methods: {
    getHistoryCar (){
      let history = localStorage.getItem('historyCars')
      this.historyCars = history ? history.split(',') : []
    },
    delHistoryCar (car){
      this.delCar = car
      this.delDialog = true
    },
    fillCar (car){
      this.newEnergyCar = car.length == 8?true:false
      this.carlicenseArr = car.split('')
    },
    beforeClose (action, done) {
      if (action === 'confirm') {
        delHistoryCars()
        this.getHistoryCar()
        done(); // 关闭提示框
      } else if (action === 'cancel') { // 取消
        done(); // 关闭提示框
      }
    },
    toPaymentInfo () {
      let that = this
      if(this.carlicenseArr.length < 7){
        Toast('请输入完整车牌')
        return
      }

      let param = {
        parkingLotCode: this.parkingLotCode,
        carLicense: this.carlicenseArr.join('')
      }
      apiInsideOrder(param)
      .then(result => {
        if (result.status == "200" && result.data.length > 0) {
          setHistoryCars(that.carlicenseArr.join(''))
          let queryParam = {
            p: this.sceneSuffix + '-' + result.data[0].parkingNo,
            // parkingLotId: result.data[0].parkingLotId
          }
          that.$router.push({
            path: '/payorder?isDesc=true',
            query: queryParam
          })
        } else if (result.status == "200" && result.data.length == 0){
          Notify({ type: 'warning', message: '未查询到相关订单' })
        } else {
          Notify({ type: "danger", message: result.message })
        }
      })
      .catch(err => {
        window.console.log(err);
      })
    },
    changeCarType() {
      this.newEnergyCar = true;
      this.carLength = 8;
    },
    showKeyBoard() {
      this.show = true;
    },
    showCnKeyBoard() {
      this.show = true;
      this.showCn = true;
      this.carlicenseArr = [];
    },
    //键盘点击完成，收起键盘
    hideKeyBoard() {
      this.show = false;
    },
    //获取按键值
    getPressKeyValue(val) {
      let car = this.carlicenseArr.join("") + val;
      if (car.length > this.carLength) {
        window.console.log("车牌长度超出");
        return;
      }
      this.carlicenseArr = car.split("");
    },
    //切换中英文键盘
    changeKeyBoard() {
      this.showCn = !this.showCn;
    },
    //按下退格键
    delCarKeyPress() {
      let car = this.carlicenseArr;
      car.pop();
      this.carlicenseArr = car;
    },
    //按下清空键
    clearCarInput() {
      this.carlicenseArr = [];
    },
  },
}
</script>

<style scoped lang="scss">
@mixin keyboard-style{
  display: flex;
  justify-content: space-between;
  padding: 0 29px;
  span{
    width: 62px;
    height: 84px;
    border: 1px solid #CCCCCC;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 32px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #343434;
    &:active{
      border: 1px solid #26A0A6;
    }
  }
  span.active{
    border: 1px solid #26A0A6;
  }
  span.dot{
    width: 40px;
    border: none;
    color: #cccccc;
    font-size: .6rem;
  }
  .new-span{
    display: inline-block;
    font-size: 10px;
    color: #999999;
    b{
      font-size: 30px;
    }
    b.text{
      font-size: 15px;
      font-weight: 300;
    }
  }
}



.inquirepay{
  .head-bg-img{
    width: 100%;
    height: 330px;
  }
  .title{
    font-size: 36px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    position: absolute;
    left: 60px;
    top: 150px;
  }
  .carlicense-box{
    width: 690px;
    height: 271px;
    background: #FFFFFF;
    box-shadow: 1px 4px 20px 0px rgba(174, 186, 190, 0.7);
    border-radius: 10px;
    margin: 0 auto;
    position: relative;
    top: -80px;
    .caption{
      font-size: 36px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #272727;
      margin-bottom: 60px;
      margin-top: 44px;
      display: inline-block;
    }
    .carlicense-spans{
      @include keyboard-style
    }
  }
  .search-btn{
    width: 600px;
    height: 80px;
    background: linear-gradient(-67deg, #26A0A6, #8EE9C6);
    border-radius: 4px;
    font-size: 28px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
  }
  .del-dialog{ 
    border-radius: 8px;
    ::v-deep .van-dialog__content{
      padding: 46px 28px 27px;
      .dialog-span{
        font-size: 30px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #9A9A9A;
        line-height: 48px;
      }
      .carlincese{
        font-size: 36px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #000000;
        margin: 0;
        margin-bottom: 36px;
      }
    }
  }
  .history{
    width: 690px;
    margin: 0 auto 80px;
    text-align: left;
    position: relative;
    top: -50px;
    .no-history{
      display: block;
      font-size: 24px;
      color: gray;
      text-align: center;
      line-height: 50px;
    }
    .title-text{
      font-size: 30px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #272727;
      position: relative;
      left: 29px;
    }
    ul{
      overflow-y: auto;
      max-height: 410px;
      li{
        height: 82px;
        position: relative;
        padding: {
          top: 30px;
          left: 29px;
          right: 29px;
        }
        border-bottom: 1px solid rgba(145, 145, 145, 0.7);
        font-size: 28px;
        color: #666666;
        box-sizing: border-box;
        .car-icon{
          width: 41px;
          height: 27px;
          margin-right: 21px;
        }
        .del-icon{
          width: 29px;
          height: 28px;
          position: absolute;
          right: 0;
          padding: 12px 30px 12px 50px;

        }
        .number{
          margin-right: 10px;
        }
      }
    }
  }
}
</style>