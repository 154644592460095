<template>
  <div class="pay-rules">
    <span class="title">计费规则</span>
    <div v-show="hasRule" class="no-rule">未查询到相关规则</div>
    <div class="rule-list">
      <span v-for="(feeRule, index) in feeRules" :key="index">{{index+1}}、{{feeRule}}</span><br/>
    </div>
    <div v-if="isNormal" class="out-time">支付后请<span> {{paidFreeTime}}分钟 </span>内离场，否则超时将重新计费。</div>
  </div>
</template>

<script>
import { apiSpecialChargeRule, apiChargeRule, apiPaidFreeTime } from "@/utils/api"
import { chargeRule } from "@/utils/common"

export default {
  props: {
    parkingLotId: String
  },
  data() {
    return {
      feeRules: [],
      paidFreeTime: 15,
      isNormal: false,
      hasRule: true
    }
  },
  mounted() {},
  computed: {},
  watch: {
    parkingLotId: function(newVal, oldVal){
      this.getChargeRuleDes()
    }
  },
  created () {},
  methods: {
    getChargeRuleDes (){
      let that = this
      let param = {
        parkingLotId: this.parkingLotId,
      }
      apiSpecialChargeRule(param)
      .then(result => {
          if (result.status == "200") {
            that.hasRule = false
            if (result.data && result.data.length > 0) {
              that.feeRules = result.data
            } else {
              apiChargeRule(param)
                .then(res => {
                  if (res.status == "200") {
                    that.feeRules = chargeRule(res.data)
                    apiPaidFreeTime(param)
                    .then(result => {
                      if (result.status == "200") {
                        that.paidFreeTime = result.data;
                        that.isNormal = true
                      } else {
                        window.console.log(result.message);
                      }
                    })
                    .catch(err => {
                      window.console.log(err);
                    })
                  } else {
                    Notify({ type: "danger", message: res.message });
                  }
                })
                .catch(err => {
                  window.console.log(err);
                });
            }
          } else {
            Notify({ type: "danger", message: res.message });
          }
      })
      .catch(err => {
        window.console.log(err)
      })
    }
  }
};
</script>

<style scoped lang="scss">
  .pay-rules{
    font-size: 20px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #666666;
    width: 690px;
    box-sizing: border-box;
    text-align: left;
    margin-top: 48px;
    margin-bottom: 48px;
    .title{
      font-size: 24px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #3AA9AE;
      margin-bottom: 15px;
      display: inline-block;
    }
    .rule-list{
      span{
        line-height: 36px;
        display: inline-block;
      }
    }
    .out-time{
      color: #f51212;
      line-height: 70px;
      span{
        font-weight: bold;
      }
    }
  }
</style>
